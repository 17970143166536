.loader_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: linear-gradient(to top, #000501 0%, #092838 100%);
}

.main_loader {
  width: 80px !important;
  height: 80px !important;
  cursor: not-allowed !important;
  border-radius: 50% !important;
  border: 2px solid #444 !important;
  box-shadow: -10px -10px 10px #94181c, 0px -10px 10px 0px #e3c84e,
    10px -10px 10px #75ca2b, 10px 0 10px #86692f, 10px 10px 10px 0px #f1f1f1,
    0 10px 10px 0px #292627, -10px 10px 10px 0px #ffb700 !important;
  animation: rot55 0.7s linear infinite !important;
  position: relative;
}
.new-font {
  font-family: "Raleway", Arial, Helvetica, sans-serif;
  font-size: 17px;
  letter-spacing: 1px;
}
.newFont {
  font-family: "Raleway", Arial, Helvetica, sans-serif;
}
.spinnerin {
  border: 2px solid #444 !important;
  width: 70px !important;
  height: 70px !important;
  border-radius: 50% !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo_loader {
  width: 70px;
  height: 70px;
  background-image: url("../images/aoss_logo.png"); /* Replace with the path to your logo */
  background-size: cover;
  background-position: center;
  border-radius: 50% !important;
  position: absolute;
  top: 50%;
  left: 50%;
}
.logo_loader_dashboard {
  transform: translate(-302%, -50%) !important;
}
.logo_loader_public {
  transform: translate(-50%, -50%) !important;
}
.loader__con p {
  font-size: 13px !important;
  font-weight: 800 !important;

  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
@keyframes rot55 {
  to {
    transform: rotate(360deg);
  }
}
.unauthorized-container {
  width: 100% !important;
  height: 100vh !important;
  background-image: linear-gradient(to top, #000405 0%, #380909 100%);
}
.unauthorized-container h1 {
  font-size: 70px;
  color: white;
  font-weight: 900;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif !important;
}
.unauthorized-container h2 {
  font-size: 40px !important;
  color: white !important;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif !important;
}
.unauthorized-container p {
  font-size: 20px !important;
  color: white !important;
  font-family: Verdana, sans-serif !important;
}

/* HTML: <div className="nonetable_loader"></div> */

/* HTML: <div className="loader"></div> */

.nonetable_loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #de3500 #0000 #fff #0000;
  border-radius: 50%;
  box-sizing: border-box;
  animation: 1s rotate linear infinite;
  margin: 0 auto;
}
.nonetable_loader:before,
.nonetable_loader:after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  border: 10px solid transparent;
  border-bottom-color: #fff;
  transform: translate(-10px, 19px) rotate(-35deg);
}
.nonetable_loader:after {
  border-color: #de3500 #0000 #0000 #0000;
  transform: translate(32px, 3px) rotate(-35deg);
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
