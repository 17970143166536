.nav-icon {
  font-size: 40px !important;
  color: rgb(174, 255, 174);
  border-radius: 50% !important;
  padding: 5px !important;
  background-image: linear-gradient(
    to right,
    #b8cbb8 0%,
    #b8cbb8 0%,
    #b465da 0%,
    #cf6cc9 33%,
    #ee609c 66%,
    #ee609c 100%
  );
}
.text span {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 17px !important;
}
.text .caption-text {
  font-size: 15px !important;
}
._hero_continer_ h1 {
  align-items: center !important;
  justify-content: center;
}

.fimg {
  filter: brightness(0.6) !important;
  height: 100% !important;
}

.heroh1s {
  font-size: 70px !important;
  max-width: 70% !important;
  text-align: center !important;
  margin: 0 auto !important;
  font-weight: 900 !important;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

  /* font-family: 'Courier New', Courier, monospace; */
}
.homep {
  font-size: 18px !important;
  font-weight: 400;
}
.heroh1sMobile {
  font-size: 23px;
  font-weight: 900 !important;
  color: #3fd40d;
  margin-top: 9px !important;
}

._carousel_captions_ {
  position: absolute;
  top: 50%;
  left: 50% !important;
  transform: translate(-50%, -50%);
  text-align: center;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .heroh1s {
    display: none !important;
  }
  ._carousel_captions_ {
    width: 500px !important;
  }
  ._carousel_captions_ .f1 {
    font-size: 70px !important;

    font-weight: 500;
  }
}

.Titel_con .tspan {
  background: linear-gradient(to top left, #eb0000, #95008a, #3300fc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.service_cards {
  border-top: 1px solid rgb(233, 230, 230);
  border-left: 1px solid rgb(243, 243, 243);
  border-radius: 68px;
  background: #fafafa;
  box-shadow: 12px 12px 0px #dddddd, 0px 0px 0px #ffffff;
  width: 360px !important;
  height: 300px !important;
  margin: 1.3%;
  padding: 20px;
}

._carousel_captions_ p {
  color: rgb(255, 255, 255) !important;

  font-size: 15px;
  max-width: 100% !important;
  border-radius: 30px;
  font-weight: 900;
  box-shadow: 0 4px 6px rgba(32, 207, 1, 0.8), 0 1px 3px rgba(0, 0, 0, 0.08);
}
._hero_continer_:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: #222;
  opacity: 0.3;
  border-bottom-right-radius: 0px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  ._hero_continer_ img {
    /* width: 100% !important; */
    /* height: 100 !important; */
  }
}
.root-navbar .root-navigation .root-menu ._lists_ > a {
  display: inline-block;
  padding: 5px 20px;
  font-family: "Raleway", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.root-navbar .root-menu > li > a._lists_ {
  position: relative;
}

.root-navbar .root-menu > li > a._lists_:hover {
  color: #fff;
}

.root-navbar .root-menu > li > a._lists_:hover:after {
  height: 2px;
  background: #fff;
  content: "";
  position: absolute;
  color: rgb(255, 255, 255) !important;
  bottom: 0;
  left: 20px;
  border-radius: 50% !important;
  right: 20px;
}

.root-navbar .root-menu > li._lists_ > a {
  position: relative;
  color: #fff;
}

.root-navbar .root-menu > li._lists_ > a:hover {
  color: #fff;
}

.root-navbar .root-menu > li._lists_ > a.active:after {
  height: 2px !important;
  background: #fff !important;
  content: "";
  border-radius: 50% !important;
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 20px;
}
.projectsec {
  margin-bottom: 40px !important;
}
.contact-links {
  text-decoration: none !important;
  color: wheat !important;
}
.mainconatct-container {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.mainconatct-section {
  margin-bottom: 110px !important;
  margin-top: 30px !important;
}
.contact-input-labels {
  font-size: 14px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.info-wrap {
  background-image: linear-gradient(to top, #00c16d 0%, #c7cc64 100%);
}
.info-wrap p {
  font-family: Arial, sans-serif;
  font-size: 17px;
}
._btn_login_ {
  --fancy-button-gradient-0: #49fdc1;
  --fancy-button-gradient-50: #ff0051d9;
  --fancy-button-gradient-100: #56f3e1;
  --fancy-button-inner-shadow-top: rgb(251, 255, 0);
  --fancy-button-inner-shadow-top-lg: rgba(255, 255, 255, 0.1);
  --fancy-button-inner-shadow-bottom: rgb(251, 255, 0);
  --fancy-button-shine-top: #3cff00;
  --fancy-button-shine-bottom: #3cff00;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  text-shadow: 0px 0.5px 0.5px rgba(0, 0, 0, 0.2);
  padding: 0;
  margin: 0;
  appearance: none;
  border: none;
  outline: none;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  z-index: 1;
  border-radius: 11%;
  color: #fff;
  background-image: linear-gradient(
    to bottom,
    var(--fancy-button-gradient-0) 0%,
    var(--fancy-button-gradient-50) 50%,
    var(--fancy-button-gradient-100) 100%
  );
  box-shadow: 0px 4px 12px rgba(9, 12, 60, 0.15),
    0px 2px 8px rgba(9, 12, 60, 0.15),
    0px 1px 3px var(--fancy-button-inner-shadow-top-lg),
    inset 0px 1px 1px var(--fancy-button-inner-shadow-top),
    inset 0px -1px 3px var(--fancy-button-inner-shadow-bottom);
}

._btn_login_:before,
._btn_login_:after {
  content: "";
  position: absolute;
  border-radius: inherit;
  will-change: transform;
}

._btn_login_:before {
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--fancy-button-shine-top);
  width: 96px;
  height: 6px;
  top: -3px;
  opacity: 0.6;
  filter: blur(6px);
  transition: opacity 0.25s;
}

._btn_login_:after {
  inset: 0;
  background-size: cover;
  z-index: 2;
  opacity: 0.3;
  mix-blend-mode: overlay;
  pointer-events: none;
}

._btn_login_ span {
  display: block;
  padding: 12px 24px;
  /* border-radius: inherit; */
  overflow: hidden;
  position: relative;
  background-image: linear-gradient(
    to bottom,
    var(--fancy-button-shine-top),
    transparent 8px
  );
  background-position: 0 -6px;
  background-repeat: no-repeat;
  z-index: 1;
}

._btn_login_ span:before,
._btn_login_ span:after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--fancy-button-shine-bottom);
  transition: opacity 0.25s, transform 0.25s;
  will-change: transform;
}

._btn_login_ span:before {
  width: 92px;
  height: 8px;
  bottom: -4px;
  opacity: 0.75;
  filter: blur(6px);
}

._btn_login_ span:after {
  width: 112px;
  height: 1px;
  bottom: 0;
  opacity: 0.9;
  filter: blur(1px);
}

._btn_login_:hover span:before {
  opacity: 0.8;
}

._btn_login_:hover span:before {
  transform: translateX(-50%) scale(1.25);
}

._btn_login_:hover span:after {
  opacity: 1;
}
.img-fluid img {
  height: 300px;
}

.form_main_ {
  width: 400px;
  height: 550px;
  padding: 50px !important;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: center !important;
  background-color: rgb(234, 228, 228);
  padding: 30px 30px 30px 30px;
  border-radius: 30px;
  box-shadow: 0px 0px 40px rgba(57, 56, 56, 0.062);
}

.heading_ {
  font-size: 2em;
  color: #20c30a;
  font-weight: 700;
}

.inputContainer_ {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputIcon_ {
  position: absolute;
  left: 10px;
}

.inputField_ {
  width: 100%;
  height: 40px;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid rgb(173, 173, 173);
  border-radius: 30px;
  margin: 10px 0;
  color: black;
  font-size: 0.8em;
  font-weight: 500;
  box-sizing: border-box;
  padding-left: 30px;
}

.inputField_:focus {
  outline: none;
  border-bottom: 2px solid green;
}

.inputField_::placeholder {
  color: rgb(80, 80, 80);
  font-size: 1em;
  font-weight: 500;
}

#button_ {
  position: relative;
  width: 285px;
  border: 2px solid rgb(0, 246, 0);
  background-color: rgb(0, 154, 0);
  height: 40px;
  color: white;
  font-size: 0.8em;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 30px;
  margin: 10px;
  cursor: pointer;
  overflow: hidden;
}
#button2_ {
  position: relative;
  width: 100%;
  border: 2px solid rgb(0, 246, 0);
  background-color: rgb(0, 154, 0);
  height: 40px;
  color: white;
  font-size: 0.8em;
  font-weight: 600;
  padding-left: 20px;
  padding-right: 20px;
  letter-spacing: 1px;
  border-radius: 10px;
  /* margin: 10px; */
  cursor: pointer;
  overflow: hidden;
}
#button_::after {
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255, 0.253);
  height: 100%;
  width: 150px;
  top: 0;
  left: -200px;
  border-bottom-right-radius: 100px;
  border-top-left-radius: 100px;
  filter: blur(10px);
  transition-duration: 0.5s;
}

#button_:hover::after {
  transform: translateX(600px);
  transition-duration: 0.5s;
}
#button2_::after {
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255, 0.253);
  height: 100%;
  width: 150px;
  top: 0;
  left: -200px;
  border-bottom-right-radius: 100px;
  border-top-left-radius: 100px;
  filter: blur(10px);
  transition-duration: 0.5s;
}

#button2_:hover::after {
  transform: translateX(600px);
  transition-duration: 0.5s;
}
.signupContainer_ {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.signupContainer_ p {
  font-size: 0.9em;
  font-weight: 500;
  color: black;
}

.signupContainer_ a {
  font-size: 0.7em;
  font-weight: 500;
  background-color: #2e2e2e;
  color: white;
  text-decoration: none;
  padding: 8px 15px;
  border-radius: 20px;
}
.nav-social-icon {
  font-size: 38px !important;
  background: #eeecec;
  border-radius: 50%;
  padding: 5px;
}
.nav-social-icon:hover {
  font-size: 38px !important;
  background: #ffffff;
  border-radius: 50%;
  padding: 1px;
}
.youtube {
  color: red;
}
.telegram {
  color: #00aaff;
}
.whatsapp {
  color: #128c7e;
}

@keyframes wipe-in-top-right {
  from {
    clip-path: polygon(-50% 50%, 50% 150%, 50% 150%, -50% 50%);
  }
  to {
    clip-path: polygon(50% -50%, 150% 50%, 50% 150%, -50% 50%);
  }
}

[transition-style="in:wipe:top-right"] {
  animation: 2.5s cubic-bezier(0.25, 1, 0.3, 1) wipe-in-top-right both !important;
}
.p-logos img {
  width: 70%;
  aspect-ratio: 3/2;
  object-fit: contain;
  padding: 0px !important;
}

.errdisplay {
  margin: 0 auto !important;
}
.otp-container {
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.otp-form-container {
  align-items: center !important;
  justify-content: center !important;
  background: #eae4e4;
  padding: 30px 65px;
  border-radius: 12px;
  row-gap: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.otp-form-container h4 {
  text-align: center;
  margin-bottom: 18px;
  font-size: 1.25rem;
  color: #333;
  font-weight: 500;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.otp-inform {
  font-size: 12px;
  width: 300px;
  text-align: center;
}
.--otp-icon-- {
  margin: 0 auto !important;
  color: orange;
}
.otp-input-field {
  flex-direction: row !important;
  column-gap: 10px !important;
  margin-bottom: 15px;
}
.otp-input-field input {
  height: 45px;
  width: 42px;
  border-radius: 6px;
  outline: none;
  font-size: 1.125rem;
  text-align: center;
  border: 1px solid #64ff97;
}
.otp-input-field input::-webkit-inner-spin-button,
.otp-input-field input::-webkit-outer-spin-button {
  display: none;
}
.otp-form {
  height: 240px;
  width: 300px;
}
.otp-icon {
  font-size: 56px !important;
  text-align: center;
}
.otp-input-field input:focus {
  border: 1px solid rgb(0, 255, 55);
}
.btn-otp {
  align-items: center !important;
}
.otp-err input {
  border: 1px solid red !important;
}
.disabledButton {
  cursor: not-allowed !important;
  background: gray !important;
  opacity: 0.7 !important;
}
.notallowed {
  cursor: not-allowed !important;
  background: gray !important;
  opacity: 0.7 !important;
}
.disabledInput {
  cursor: not-allowed !important;
  background: rgb(222, 222, 222) !important;
  opacity: 0.7 !important;
}
.otp-btn-container {
  justify-content: center !important;
}
.div-center {
  margin: 0 auto;
}
.employees-table-tr {
  font-size: 12.5px !important;
  font-family: Helvetica !important;
  /* align-items: center !important; */
  /* display: block !important; */
  /* text-align: start !important; */
}

.employee-icon {
  font-size: 60px !important;
}
.employee-overview {
  background: rgba(6, 96, 141, 0.985) !important;
  border: 1px solid white;
}
.created_at {
  background: #6f00005f !important;
  width: fit-content;
  border-radius: 5px;
  border: 1px solid rgb(82, 82, 82);
  padding: 2px;
}
.sold_date {
  background: #00ccffb7 !important;
  width: fit-content;
  border-radius: 5px;
  border: 1px solid rgb(82, 82, 82);
  padding: 2px;
}
.store_date {
  background: #1d355fb7 !important;
  width: fit-content;
  border-radius: 5px;
  border: 1px solid rgb(194, 194, 194);
  padding: 2px;
}
.activity h6 {
  font-size: 12px !important;
}
.sold_date-format {
  background: #031acdb7 !important;
  width: fit-content;
  border-radius: 5px;
  border: 1px solid rgb(82, 82, 82);
  padding: 2px;
}
.exp-date-format {
  background: rgb(110, 77, 2) !important;
  width: fit-content;
  border-radius: 5px;
  border: 1px solid rgb(255, 255, 255);
  padding: 2px;
}
.last_seen {
  background: rgb(137, 3, 45) !important;
  width: fit-content;
  border-radius: 5px;
  border: 1px solid rgb(255, 255, 255);
  padding: 2px;
}
.store_date-format {
  background: #031acdb7 !important;
  width: fit-content;
  border-radius: 5px;
  border: 1px solid rgb(82, 82, 82);
  padding: 2px;
}
.store_exp-date-format {
  background: rgb(108, 1, 122) !important;
  width: fit-content;
  border-radius: 5px;
  border: 1px solid rgb(255, 255, 255);
  padding: 3px;
}
.bgsecondarybar {
  background: #000329 !important;
}
.bgsecondarypie {
  background: #083042 !important;
}
.bg-dd {
  background: #01001c !important;
}
#sexPieChart {
  height: 100% !important;
  width: 50% !important;
  margin: 0 auto !important;
}
#ProductTypePieChart {
  height: 100% !important;
  width: 58% !important;
  margin: 0 auto !important;
}
#contributionStatusDoughnutChart {
  height: 100% !important;
  width: 50% !important;
  margin: 0 auto !important;
}
#ContributionBarChart {
  height: 100% !important;
  width: 100% !important;
  margin: 0 auto !important;
}
.Contbarspan {
  padding-bottom: 4px !important;
  font-weight: 500;
  align-items: center;
}

#AgroTransProfileLineChart {
  height: 100% !important;
  width: max-content !important;
}
#addressBarChart {
  height: 100% !important;
  width: 100% !important;
}
#reportdoughnutChart {
  height: 100% !important;
  width: 60% !important;
  margin: 0 auto !important;
}
.pie-mf {
  padding: 21.9px !important;
}

.woftrans {
  width: 100% !important;
}
.woftranspie {
  width: 44% !important;
}

@media (max-width: 768px) {
  .colsmdiv {
    width: 100% !important;
  }
  .woftrans {
    width: 100% !important;
  }
}

.search input {
  width: 100% !important;
}
.bsecondary {
  background: #04011a;
  border: 2px #818cf8 solid;
}
.bsecondaryns {
  background: #1a0101;
  border: 2px #f88181 solid;
}
.custom-alert-warning {
  background: #650000 !important;
  border: 2px solid #ff0000 !important;
}

.bgcustom {
  border: 1px solid gray !important;
  background: rgb(16, 4, 26);
}
.dashboardcolor {
  background: #1c2030 !important;
}
.bginfo {
  background: #000a0b !important;
  border-bottom: 1px solid grey;
}
.select- {
  width: 100% !important;
}
/* Filter Card */
.filter-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999; /* Ensure the overlay is below the filter card */
}

.filter-card {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #cbcbcb;
  border-radius: 30px;
  padding: 20px;
  width: 600px;
  height: 600px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
  z-index: 1000;
}

.filter-card.active {
  display: block;
}

/* Toggle Button */
.btn-outline-primary {
  cursor: pointer;
}

/* Apply the overlay when the filter card is active */
.filter-card.active + .filter-overlay {
  display: block;
  pointer-events: auto;
}

.bgalert {
  background-color: #000000 !important;
}

.badge-w10 {
  width: 10% !important;
}
.badge-w15 {
  width: 15% !important;
}
.badge-w20 {
  width: 20% !important;
}
.sharecon {
  border: 1px solid gainsboro !important;
}
.Sicon {
  color: whitesmoke !important;
}
.MessageIC {
  font-size: 80px !important;
  align-self: center !important;
  justify-content: center !important;
}
.text-gray {
  color: gray !important;
}
/* .bg-transparent{
  background-color: black !important;
  opacity: 0.3 !important;
} */
.bg-report {
  color: #b53638 !important;
}

.newsalesicon {
  font-size: 65px !important;
  color: white !important;
}
.txt-mysm {
  font-size: 12.5px !important;
}
.pro-conteiner {
  border-bottom: 1px solid gray;
  border-top: 1px solid gray;
  /* background-color: #1c1a1a; */
  width: 100% !important;
  padding-top: 10px;
  margin-left: 10px;
  margin-right: auto;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(26, 255, 0, 0.1);
}

.pro-conteiner:hover {
  border-bottom: 1px solid gray;
  /* background-color: #1c1a1a; */
  width: 100% !important;
  padding-top: 10px;
  margin-left: 10px;
  margin-right: auto;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(26, 255, 0, 0.2);
  transition: box-shadow 0.3s ease-in-out;
}

.pro-conteiner:hover {
  box-shadow: 0 0 10px rgba(26, 255, 0, 0.7);
}

.vl {
  border-left: 1px solid rgb(158, 158, 158);
  height: 500px;
}

.custom-pill-pink {
  color: white;
  border: 1px solid #d86ded;
  padding: 3px;
  background: #200e24;
  border-radius: 10px;
}
.custom-pill-yellow {
  color: white;
  border: 1px solid #ebed6d;
  padding: 3px;
  background: #342b12;
  border-radius: 10px;
}

.ssic {
  font-size: 43px !important;
}
.ssic:hover {
  font-size: 41px !important;
  color: yellow;
}

/* HTML: <div className="btn-loader"></div> */
.btn-loader {
  color: #000;
  width: 4px;
  margin: 0 auto !important;
  align-items: center;
  aspect-ratio: 1;
  border-radius: 50%;
  box-shadow: 19px 0 0 7px, 38px 0 0 3px, 57px 0 0 0;
  transform: translateX(-38px);
  animation: l21 0.5s infinite alternate linear;
}
.feedback-msg {
  align-items: center !important;
  margin-top: 20%;
  text-align: center;
}
.fb-icon {
  font-size: 110px !important;
  color: rgb(0, 200, 0) !important;
}
@keyframes l21 {
  50% {
    box-shadow: 19px 0 0 3px, 38px 0 0 7px, 57px 0 0 3px;
  }
  100% {
    box-shadow: 19px 0 0 0, 38px 0 0 3px, 57px 0 0 7px;
  }
}

.custom-alert-danger {
  background: rgb(61, 4, 4) !important;
  color: rgb(255, 255, 255) !important;
  width: 96.6%;
  margin: 0 auto !important;
  text-decoration: none;
  border: 1px solid gray !important;
}
.surveynoticetxt {
  font-family: serif;
}
.custom-card-fb {
  width: 93% !important;
  background-color: #060622 !important;
  margin: auto auto;
  margin-top: 40px;
  height: fit-content !important;
}
.card_lis {
  font-size: 16.5px !important;
  font-style: oblique;
  font-weight: 500;
}
.custom-card-body-fb-child {
  background: #0b09065f !important;
  border: 1px solid darkblue;
}
.custom-card-body-fb:hover .custom-card-body-fb-child {
  background: #000000 !important;
  border: 1px solid rgb(0, 26, 255);
}
.errricon {
  font-size: 29.5px !important;
  color: red !important;
}
.erri {
  font-size: 16.5px !important;
}
/* HTML:  */
.loader2 {
  width: 120px;
  height: 22px;
  border-radius: 40px;
  color: #ea00ff;
  border: 2px solid;
  position: relative;
  overflow: hidden;
  margin: 0 auto !important;
}
.loader2::before {
  content: "";
  position: absolute;
  margin: 2px;
  width: 14px;
  top: 0;
  bottom: 0;
  left: -20px;
  border-radius: inherit;
  background: currentColor;
  box-shadow: -10px 0 12px 3px rgb(255, 255, 255);
  clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 95%, -30px 50%);
  animation: l14 1.3s infinite linear;
}
._submited {
  border: 2px solid rgb(255, 255, 255) !important;
  background: #0d0610 !important;
}
@keyframes l14 {
  100% {
    left: calc(100% + 20px);
  }
}

.btn-grad1 {
  background-image: linear-gradient(
    to right,
    #fc00ff 0%,
    #00dbde 51%,
    #fc00ff 100%
  );
  /* margin: 10px; */
  padding: 6px 15px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
  border-color: #00aaff;
  outline: none;
}

.btn-grad1:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.module-border-wrap {
  max-width: 100%;
  margin: 0 auto;
  border: 3px solid gray;
  padding: 1rem;
  position: relative;
  background: linear-gradient(to right, rgb(146, 66, 66), rgb(142, 59, 142));
  padding: 3px;
}

.module {
  background: #222;
  color: white;
  padding: 2rem;
}
.text-custom-success {
  color: rgb(255, 255, 255) !important;
}
.text-custom-success:hover {
  color: rgb(0, 255, 0) !important;
}
.gradient-border-pseudo-- {
  color: white !important;
  border: 1px solid gray !important;
  border-radius: 20px !important;
}
.custom-pill-green {
  border: 1px solid rgb(89, 228, 89) !important;
  background-color: rgb(14, 41, 14) !important;
  padding: 1px !important;
  border-radius: 18% !important;
}
.gradient-border-pseudo h3 {
  font-size: 30px;
  color: #ffffff;
  font-weight: 900;
}
.gradient-border-pseudo p {
  font-size: 14px;
  color: #001861;
  font-weight: 900;
}
.gradient-border-pseudo .dif {
  font-size: 14px;
  color: #000000;
  font-weight: 900;
}
.licon {
  border: 1px solid gold !important;
}
.adi {
  font-size: 85px !important;
  background: purple;
  color: white;
  border-radius: 50%;
}
.act-defualt-name-con p {
  font-size: 13px !important;
}
.act-defualt-status-con {
  background: #b53638;
  color: white;
  border: 1px solid #ff0004;
  padding: 5%;
  border-radius: 10%;
}
.dropdownconteaner {
  margin-top: 10px !important;
  border: 1px solid rgb(124, 124, 124);
  padding: 20px !important;
  border-radius: 10px;
}

.isDRP {
  transition: transform 0.5s ease;
}

.isDRP {
  transform: rotate(180deg);
}
.noDRP {
  transition: transform 0.5s ease;
}

.report-defualt-name-con p {
  font-size: 13px !important;
}
.report-defualt-status-con {
  background: #000000;
  color: white;
  border: 1px solid #00a6ff;
  padding: 5%;
  border-radius: 10%;
}
.report_licon {
  border: 1px solid rgb(161, 161, 161) !important;
}
.report_adi {
  font-size: 85px !important;
  background: rgb(0, 254, 119);
  color: rgb(0, 0, 0);
  border-radius: 50%;
}
.aedjr {
  background: rgb(33, 33, 14);
  border: 1px solid goldenrod !important;
}
.aedjr {
  transition: transform 0.9s ease !important;
}
.rdropdownconteaner {
  margin-top: 10px !important;
  border-top: 1px solid rgb(124, 124, 124);
  padding: 20px !important;
  border-radius: 10px;
}
.aedjr:hover {
  background: rgb(69, 69, 28);
  border: 1px solid rgb(33, 33, 14) !important;
}
.Dcon {
  position: relative;
  height: 100vh;
  background: #212529 !important;
}

.center-content h1 {
  font-weight: 900 !important;
  width: 100%;
  margin-top: 15px;
  font-size: 25px !important;
}
.center-content h1 {
  background: -webkit-linear-gradient(#000000, #35a5e6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.center-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.clip-text {
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn-ccgray {
  background: #4d062f !important;
  border: 1px solid #cd005f !important;
  border-radius: 3px !important;
  font-size: 14px !important;
  padding: 3px !important;
}
.store-table-container {
  border: 1px solid grey !important;
}
.stprechart {
  background: #000000 !important;
}
.s-outed-con {
  border: 1px solid rgb(255, 255, 255) !important;
  background: #0e0e10 !important;
}
.outps {
  border: 1px solid rgb(255, 255, 255) !important;
  background: #212529 !important;
  border-radius: 10px !important;
  padding: 20px !important;
}
.tbl-out {
  border: 1px solid rgb(118, 118, 117) !important;
  border-radius: 60px !important;
}
.fns-selected {
  font-size: 30px !important;
  color: rgb(0, 255, 0) !important;
}
.fnbtn {
  outline: none !important;
  border: none !important;
  background: none !important;
}

.fns {
  font-size: 30px !important;
  color: rgb(255, 255, 255) !important;
}
.truthveriIcon {
  color: white !important;
  font-size: 100px !important;
}

.refetchdataicon {
  font-size: 35px !important;
  color: #01ff38 !important;
}
.fetchiconOnloading {
  font-size: 35px !important;
  color: #01ff38 !important;
  animation: rotateAnimation 2s linear infinite; /* Adjust timing as needed */
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.online-employee-card {
  border: 1px solid gray;
  color: white;
  border-radius: 10px;
}
.itemWithIconActive {
  /* border: 1px solid orange !important; */
  background-color: rgb(3, 3, 3) !important;
}
.itemWithIconActive.active i {
  background: #229922 !important;
  border: 1px solid rgb(0, 250, 12) !important;
}
