.header-top > .container {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100% !important;
}
.minbg {
  background-image: linear-gradient(
      135deg,
      rgba(181, 209, 42, 0.5) 0%,
      rgba(181, 209, 42, 0.5) 16%,
      rgba(38, 179, 43, 0.5) 16%,
      rgba(38, 179, 43, 0.5) 49%,
      transparent 49%,
      transparent 96%,
      rgba(252, 224, 41, 0.5) 96%,
      rgba(227, 200, 78, 0.5) 100%
    ),
    linear-gradient(
      45deg,
      rgb(181, 209, 42) 0%,
      rgb(181, 209, 42) 8%,
      rgb(38, 179, 43) 8%,
      rgb(38, 179, 43) 31%,
      transparent 31%,
      transparent 45%,
      rgb(255, 255, 255) 45%,
      rgb(255, 255, 255) 100%
    ),
    linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
}
.header-top .quick-contact-icons .icon {
  margin-right: 10px;
}
.header-top .quick-contact-icons .icon span {
  font-size: 40px;
  line-height: 0;
}
.header-top .quick-contact-icons .text {
  line-height: 1;
}
.header-top .quick-contact-icons .text .h4 {
  font-size: 15px;
  color: #000000;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.header-top .quick-contact-icons .text .sub-text {
  margin-top: 3px !important;
  font-size: 13.7px !important;
  font-weight: 500;
  font-family: "Raleway", Arial, Helvetica, sans-serif;
}
.logo {
  position: relative;
  top: 5px;
}
.root-navbar {
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 0px;
  z-index: 1999;
  width: 100%;
}
.root-navbar .container-fluid {
  padding-left: 7rem;
  padding-right: 7rem;
}
.root-navbar .root-navigation.border-bottom {
  border-bottom: 1px solid #f3f3f4 !important;
}
.root-navbar .root-navigation .root-menu {
  margin-bottom: 0;
}
.root-navbar .root-navigation .root-menu a {
  text-decoration: none !important;
  display: inline-block;
}
.root-navbar .root-navigation .root-menu > li {
  display: inline-block;
}
.root-navbar .root-navigation .root-menu > li .desc {
  font-size: 12px;
  color: #969696;
}
.root-navbar .root-navigation .root-menu > li > a {
  font-size: 17px;
  letter-spacing: 0.05rem;
  padding: 17px 17px;
  color: rgb(255, 255, 255);
  display: inline-block;
  text-decoration: none !important;
}
.root-navbar .root-navigation .root-menu > li > a:hover {
  color: #fff;
}
.root-navbar .root-navigation .root-menu .has-children {
  position: relative;
}
.root-navbar .root-navigation .root-menu .has-children > a {
  position: relative;
  padding-right: 20px;
}
.root-navbar .root-navigation .root-menu .has-children > a:before {
  position: absolute;
  content: "\e313";
  font-size: 16px;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
.root-navbar .root-navigation .root-menu .has-children .dropdown {
  visibility: hidden;
  opacity: 0;
  top: 100%;
  position: absolute;
  text-align: left;
  border-top: 2px solid #000;
  -webkit-box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
  border-left: 1px solid #edf0f5;
  border-right: 1px solid #edf0f5;
  border-bottom: 1px solid #edf0f5;
  padding: 0px 0;
  margin-top: 20px;
  margin-left: 0px;
  background: #fff;
  -webkit-transition: 0.2s 0s;
  -o-transition: 0.2s 0s;
  transition: 0.2s 0s;
}
.root-navbar .root-navigation .root-menu .has-children .dropdown.arrow-top {
  position: absolute;
}
.root-navbar
  .root-navigation
  .root-menu
  .has-children
  .dropdown.arrow-top:before {
  bottom: 100%;
  left: 20%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.root-navbar
  .root-navigation
  .root-menu
  .has-children
  .dropdown.arrow-top:before {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}
.root-navbar .root-navigation .root-menu .has-children .dropdown a {
  font-size: 16px;
  text-transform: none;
  letter-spacing: normal;
  -webkit-transition: 0s all;
  -o-transition: 0s all;
  transition: 0s all;
  color: #343a40;
}
.root-navbar .root-navigation .root-menu .has-children .dropdown .active > a {
  color: #653311 !important;
}
.root-navbar .root-navigation .root-menu .has-children .dropdown > li {
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 200px;
}
.root-navbar .root-navigation .root-menu .has-children .dropdown > li > a {
  padding: 9px 20px;
  display: block;
}
.root-navbar
  .root-navigation
  .root-menu
  .has-children
  .dropdown
  > li
  > a:hover {
  background: #f4f5f9;
  color: #25262a;
}
.root-navbar
  .root-navigation
  .root-menu
  .has-children
  .dropdown
  > li.has-children
  > a:before {
  content: "\e315";
  right: 20px;
}
.root-navbar
  .root-navigation
  .root-menu
  .has-children
  .dropdown
  > li.has-children
  > .dropdown,
.root-navbar
  .root-navigation
  .root-menu
  .has-children
  .dropdown
  > li.has-children
  > ul {
  left: 100%;
  top: 0;
}
.root-navbar
  .root-navigation
  .root-menu
  .has-children
  .dropdown
  > li.has-children:hover
  > a,
.root-navbar
  .root-navigation
  .root-menu
  .has-children
  .dropdown
  > li.has-children:active
  > a,
.root-navbar
  .root-navigation
  .root-menu
  .has-children
  .dropdown
  > li.has-children:focus
  > a {
  background: #f4f5f9 !important;
  color: #25262a;
}
.root-navbar .root-navigation .root-menu .has-children:hover > a,
.root-navbar .root-navigation .root-menu .has-children:focus > a,
.root-navbar .root-navigation .root-menu .has-children:active > a {
  color: #000;
}
.root-navbar .root-navigation .root-menu .has-children:hover,
.root-navbar .root-navigation .root-menu .has-children:focus,
.root-navbar .root-navigation .root-menu .has-children:active {
  cursor: pointer;
}
.root-navbar .root-navigation .root-menu .has-children:hover > .dropdown,
.root-navbar .root-navigation .root-menu .has-children:focus > .dropdown,
.root-navbar .root-navigation .root-menu .has-children:active > .dropdown {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  margin-top: 0px;
  visibility: visible;
  opacity: 1;
}
.root-navbar .root-navigation .root-menu.root-menu-dark > li > a {
  color: #000;
}
.root-navbar {
  /* -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease; */
  background: #11860c !important;
}

.home-nav-link {
  color: #fff;
  text-decoration: none;
  font-size: 1em;
  padding: 5px 10px;
  transition: background-color 0.3s ease;
  cursor: pointer !important;
}

.root-navbar .root-logo a {
  color: #000;
}
.root-navbar .root-menu > li {
  display: inline-block;
}

.is-sticky .root-navbar {
  -webkit-box-shadow: 4px 0 20px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 4px 0 20px -5px rgba(0, 0, 0, 0.2);
}
.is-sticky .root-navbar .root-logo a {
  color: #fff;
}
.is-sticky .root-navbar .root-menu > li {
  display: inline-block;
}
.is-sticky .root-navbar .root-menu > li > a {
  padding: 10px 20px;
  display: inline-block;
  text-decoration: none !important;
}
.is-sticky .root-navbar .root-menu > li > a:hover {
  color: #fff;
}
.is-sticky .root-navbar .root-menu > li > a.active:after {
  background: #ffffff;
}
.is-sticky .root-navbar .root-menu > li.active > a {
  color: #fff;
}
.shrink {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.scr {
  width: 100%;
  position: fixed !important;
  top: 0 !important;
  background-color: #189445 !important;
  z-index: 100 !important;
  /* border-bottom-left-radius: 40px !important;
  border-bottom-right-radius: 40px !important; */
}
@keyframes wipe-in-right {
  from {
    clip-path: inset(0 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

[transition-style="in:wipe:right"] {
  animation: 2.5s cubic-bezier(0.25, 1, 0.3, 1) wipe-in-right both !important;
}
@keyframes wipe-in-left {
  from {
    clip-path: inset(0 0 0 100%);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

[transition-style="in:wipe:left"] {
  animation: 2.5s cubic-bezier(0.25, 1, 0.3, 1) wipe-in-left both;
}
.gotodashboard {
  display: inline-block;
  width: 150px;
  height: 43px;
  border-radius: 10px;
  outline: none;
  border: none;
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease-in;
  background: rgb(255, 255, 255);
  z-index: 1;
}

.gotodashboard::before,
.gotodashboard::after {
  content: "";
  position: absolute;
  top: 0;
  width: 0;
  height: 100%;
  transform: skew(182deg);
  transition: all 0.5s;
  overflow: hidden;
  z-index: -1;
}

.gotodashboard::before {
  left: -10px;
  background: rgb(48, 255, 21);
}

.gotodashboard::after {
  right: -10px;
  background: rgb(255, 255, 255);
}

.gotodashboard:hover::before,
.gotodashboard:hover::after {
  width: 58%;
}

.gotodashboard:hover span {
  color: #420017;
  transition: 0.3s;
}

.gotodashboard span {
  color: #03045e;
  font-size: 18px;
  transition: all 0.3s ease-in;
}
