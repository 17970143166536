/********** Template CSS **********/
:root {
  --primary: #eb1616;
  --secondary: rgb(24, 23, 23);
  --light: #ffffffad;
  --dark: #00020e;
  --link: #dddd;
  --bs-body-bg: #131920;
}
.bg-custom-d- {
  background-color: #131920 !important;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}
.back-to-top {
  background: #011125 !important;
}
/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  transition: 0.5s;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 50px;
}

.table thead > tr.rowA1.rowA1 > th {
  border-bottom: 1px solid red;
}

.table tbody > tr.rowB2.rowB2 > td {
  border-bottom: 1px solid lime;
}

.table tfoot > tr.rowC2.rowC2 > td {
  border-bottom: 1px solid blue;
}

/*** Layout ***/
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 250px;
  height: 100vh;
  overflow-y: hidden;
  /* background: v; */
  transition: 0.5s;
  z-index: 999;
}
.sidebar::-webkit-scrollbar {
  width: 5px;
}
.sidebar::-webkit-scrollbar-track {
  background: #131920; /* Color of the track */
  /* border-radius: 50%; */
}

.sidebar:hover {
  overflow-y: auto; /* Show vertical scrollbar on hover */
}

.content {
  margin-left: 250px;
  min-height: 100vh;
  background: var(--dark);
  transition: 0.5s;
}

@media (min-width: 992px) {
  .sidebar {
    margin-left: 0;
  }

  .sidebar.open {
    margin-left: -250px;
  }

  .content {
    width: calc(100% - 250px);
  }

  .content.open {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 991.98px) {
  .sidebar__ {
    margin-left: -250px;
  }

  .sidebar__.open__ {
    margin-left: 0;
  }

  .content__ {
    width: 100%;
    margin-left: 0;
  }
}

/*** Navbar ***/
.sidebar .navbar .navbar-nav .nav-link {
  padding: 7px 20px;
  color: var(--light);
  font-weight: 500;
  border-left: 3px solid var(--secondary);
  border-radius: 0 30px 30px 0;
  outline: none;
}

.sidebar .navbar .navbar-nav .nav-link:hover,
.sidebar .navbar .navbar-nav .nav-link.active {
  color: white;
  background: var(--dark);
  border-color: green;
}

.sidebar .navbar .navbar-nav .nav-link i {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #000a0250;
  border-radius: 50px;
  border: 1px solid #001f0550;
  transition: all 0.3s ease-in-out;
}

.sidebar .navbar .navbar-nav .nav-link:hover i,
.sidebar .navbar .navbar-nav .nav-link.active i {
  background: #229922;
  border: 1px solid rgb(0, 250, 12);
}

.sidebar .navbar .dropdown-toggle::after {
  position: absolute;
  top: 15px;
  right: 15px;
  align-items: center;
  justify-content: center;
  align-items: center;
  border: none;
  content: "▿";
  font-weight: 900;
  font-size: 14px;
  transition: 0.5s !important;
}

.sidebar .navbar .dropdown-toggle[aria-expanded="true"]::after {
  transform: rotate(-180deg) !important;
  color: #ffffff;
}

.sidebar .navbar .dropdown-item {
  padding-left: 25px;
  border-radius: 0 30px 30px 0;
  color: var(--light);
}

.sidebar .navbar .dropdown-item:hover,
.sidebar .navbar .dropdown-item.active {
  background: var(--dark);
}

.content .navbar .navbar-nav .nav-link {
  margin-left: 25px;
  padding: 12px 0;
  color: var(--light);
  outline: none;
}
.wkslcard-body {
  border: 1px solid rgb(0, 199, 36);
  border-radius: 7px !important;
}
.wkslcard {
  border: none !important;
  margin-left: 25px;
  padding: 12px 0;
  padding: 7px 20px;
}
.wkslcard p {
  font-size: 12px !important;
}
.wkslcard h6 {
  font-size: 13px !important;
}
.content .navbar .navbar-nav .nav-link:hover,
.content .navbar .navbar-nav .nav-link.active {
  color: var(--primary);
}

.content .navbar .sidebar-toggler,
.content .navbar .navbar-nav .nav-link i {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--dark);
  border-radius: 40px;
}

.content .navbar .dropdown-item {
  color: var(--light);
}

.content .navbar .dropdown-item:hover,
.content .navbar .dropdown-item.active {
  background: var(--dark);
}

.content .navbar .dropdown-toggle::after {
  margin-left: 6px;
  vertical-align: middle;
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  transition: 0.5s !important;
}

.content .navbar .dropdown-toggle[aria-expanded="true"]::after {
  transform: rotate(-180deg) !important;
}

@media (max-width: 575.98px) {
  .content .navbar .navbar-nav .nav-link {
    margin-left: 15px;
  }
}

/*** Date Picker ***/
.bootstrap-datetimepicker-widget.bottom {
  top: auto !important;
}

.bootstrap-datetimepicker-widget .table * {
  border-bottom-width: 0px;
}

.bootstrap-datetimepicker-widget .table th {
  font-weight: 500;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  padding: 10px;
  border-radius: 2px;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background: var(--primary);
}

.bootstrap-datetimepicker-widget table td.today::before {
  border-bottom-color: var(--primary);
}

/*** Testimonial ***/
.progress .progress-bar {
  width: 0px;
  transition: 2s;
}

/*** Testimonial ***/
.testimonial-carousel .owl-dots {
  margin-top: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 15px;
  height: 15px;
  border: 5px solid var(--primary);
  border-radius: 15px;
  transition: 0.5s;
}

.testimonial-carousel .owl-dot.active {
  background: var(--dark);
  border-color: var(--primary);
}

/* Customer */

.profile-dislay {
  float: left !important;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
  height: 600px !important;
  width: 100%;
}
.name_display {
  background: #ff8800;
  font-family: Serif, Arial, Helvetica, sans-serif;
  font: bold !important;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.profile-icon {
  height: 150px;
  width: 150px;
  background-image: linear-gradient(to right, #00dbde 0%, #fc00ff 100%);
  border-radius: 50%;
  margin-bottom: 25px;
  margin: 0 auto;
}
/* .add-new-button button {
  float: right !important;
} */

.customer_detail-div {
  height: fit-content;
}
.transaction {
  background-color: #1e1e1e;
  border: 1px solid rgb(68, 66, 66) !important;
}
.transaction,
table {
  border: none;
  color: #bfffea !important;
}
.customer_searchbar {
  float: right;
}

.sidebar_ {
  margin-left: 0;
}

.sidebar_.open_ {
  margin-left: -250px;
}

.content_.open_ {
  width: 100%;
  margin-left: 0;
}

.sidebarmenu {
  align-content: center;
  align-items: center;
  text-align: center;
  justify-content: center;
}

/* Keyframes for the fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Base styles for dropdown menu links */
.dropdown-menu-links a {
  color: rgba(159, 177, 185, 0.911) !important;
  font-size: 16px;
  font-family: Helvetica, sans-serif;
  opacity: 0;
  /* Initially hidden */
  transform: translateX(-10px);
  /* Slightly off-screen */
  animation: fadeIn 0.1s ease forwards;
  /* Play animation */
}

/* Add animation delay for sequential reveal */
.dropdown-menu-links a:nth-child(1) {
  animation-delay: 0s;
}

.dropdown-menu-links a:nth-child(2) {
  animation-delay: 0.1s;
}

.dropdown-menu-links a:nth-child(3) {
  animation-delay: 0.2s;
}
.dropdown-menu-links a:nth-child(4) {
  animation-delay: 0.3s;
}
.dropdown-menu-links a:nth-child(5) {
  animation-delay: 0.4s;
}
.dropdown-menu-links a:nth-child(6) {
  animation-delay: 0.5s;
}
.dropdown-menu-links a:nth-child(7) {
  animation-delay: 0.6s;
}
.dropdown-menu-links a:nth-child(8) {
  animation-delay: 0.7s;
}
.dropdown-menu-links a:nth-child(9) {
  animation-delay: 0.8s;
}
/* Add more as needed based on number of links */

/* Hover effects */
.dropdown-menu-links a:hover {
  background: #85400c4c !important;
  border-left: 0;
  color: #ffffff !important;
  font-weight: 400;
}

.dropdown-menu-links a:hover:before {
  transform: translateX(5px) !important;
}

/* Arrow animation */
.dropdown-menu-links a:before {
  content: "→";
  width: 2rem;
  display: inline-block;
  position: relative;
  text-align: start !important;
  transform: translateX(0) !important;
  transition: all 0.3s ease !important;
}
.msg-notif-icon {
  font-size: 41px !important;
  justify-content: center;
  color: #fefefe;
  padding: 5px;
  border: 1px solid rgb(141, 135, 135);
}

.msg-notif-icon {
  background: rgb(15, 25, 41);
  background-size: 360% 360%;
}
.vi_card {
  padding-bottom: 20px !important;
  padding-top: 20px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.matrialuiicon {
  font-size: 50px !important;
  justify-content: center;
}
.matrialuiicon- {
  font-size: 47px !important;
}
.mit {
  background: white !important;
  padding: 5px;
}
.matrialuiicon_profile {
  width: 95px !important;
}
.online_employee-no-pic-icon {
  font-size: 70px !important;
}
.sidebar_profile_placeholder {
  font-size: 95px !important;
}
.nav_profile_placeholder {
  font-size: 50px !important;
}
.onlinesymbol {
  padding: 5.2px !important;
}
.menu-icon {
  color: #ff6600f1;
  font-size: 47px;
}

.acenter-btn {
  margin: 0 auto;
  width: 100%;
}

.prm {
  background: #eb1616;
  color: white;
  outline: none;
  border: none;
  font-size: 25px;
  padding: 9px;
  border-radius: 5px;
}
.prm:hover {
  background: #0fff0f;
  color: rgb(0, 0, 0);
  outline: none;
  border: none;
  font-size: 25px;
  padding: 9px;
  border-radius: 5px;
}

.icon-person {
  color: #f5f5f5;
  font-size: 150px !important;
}
.customer_icont {
  text-align: center !important;
}
.customer_icont img {
  height: 155px;
  width: 155px;
}
.revenue-div:hover .revenue-icon {
  color: #000000 !important;
}
.revenue-div {
  background-image: radial-gradient(
    circle 897px at 9% 80.3%,
    rgba(55, 60, 245, 1) 0%,
    rgba(234, 161, 15, 0.9) 100.2%
  );
}
.blue {
  background-color: blue !important;
}
.nav-item-div {
  align-items: center; /* Vertical centering */
}

.text-center- {
  margin-left: 30px;
  background-color: #113b637d !important;
}
.text-center-- {
  margin-left: 30px !important;
  width: fit-content;
}
.bg-active-customer {
  background-color: #131920;
}
.customer-icon {
  font-size: 55px !important;
  color: #ffffff !important;
}
.add-newcoustomericon {
  color: #f6f6f6;
  font-size: 60px !important;
  text-align: end;
  border: 1px solid #00bed3;
  padding: 5px;
  border-radius: 50%;
  width: fit-content;
  transition: transform 0.5s !important;
}

.add-newcoustomericon:hover {
  transform: scale(1.1);
  color: #00bed3;
  font-size: 60px !important;
  text-align: end;
  border: none;
  padding: 5px;
  border-radius: 50%;
  width: fit-content;
}
.tab-header {
  display: flex;
  justify-content: space-between;
}
.pm {
  background: #eb1616 !important;
  color: white !important;
}
.xpm {
  color: var(--primary);
}
.form-select-custom {
  background: #d3d3d3 !important;
  color: rgb(0, 0, 0) !important;
}
.form-select-date-custom {
  background: white !important;
  color: white !important;
}
.custom-form-control {
  background: #b7b7b7 !important;
}
.custom-form-control:focus {
  background: #fdfdfd !important;
}
.bgsecondary {
  background: #0e0e0e !important;
  color: white;
}
.dashboard-main {
  height: 100% !important;
}
.secondarybg {
  background: #1d2630 !important;
}
.salesicon {
  font-size: 60px !important;
  color: var(--primary);
}
.container-fluid-custom {
  margin: 0 auto;
  height: auto;
  border-radius: 10px;
  border: 1px solid gray;
  background: #8a8a8a;
}
.upicon {
  font-size: 50px !important;
  text-align: center;
  color: #0fff0f !important;
}
.sidebar-icon {
  color: #c2d9d9 !important;
}

.loader {
  display: block;
  position: relative;
  height: 9px;
  width: 100%;
  border-radius: 1px;
  overflow: hidden;
}
.loader::after {
  content: "";
  width: 100px;
  height: 9px;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  animation: hitZak 0.9s ease-in-out infinite alternate;
}
.loader::after {
  background: #ff3c00;
}

@keyframes hitZak {
  0% {
    left: 0;
    transform: translateX(-1%);
  }
  100% {
    left: 100%;
    transform: translateX(-99%);
  }
}

#DashboardSearchinput::placeholder {
  color: #ffffff;
  opacity: 1;
}
.DashboardSearch {
  border: 1px solid gray !important;
}
.text-light-gray {
  color: var(--light) !important;
}
.aligncontentcenter {
  margin: 0 auto !important;
}
.cdcard {
  margin: 0 auto !important;
  justify-content: center !important;
}

.cta {
  position: relative !important;
  margin-left: 30px !important;
  padding: 12px 18px;
  transition: all 0.2s ease !important;
  border: none;
  background: none;
  animation: fadeIn 0.1s ease forwards;
}
.cta a:nth-child(1) {
  animation-delay: 0.2s;
}

.cta a:nth-child(2) {
  animation-delay: 0.3s;
}
.cta:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: 1px solid #6c6ce0;
  background: rgba(204, 7, 230, 0.149);
  color: white;
  width: 55px;
  text-decoration: none !important;
  height: 45px;
  margin-bottom: 4px;
  transition: all 0.3s ease;
}
.wkslcard-body {
  background-color: #4caf50; /* Start with a green color */
  transition: background-color 0.9s ease !important; /* Lights animation */
  animation: lights 1s infinite alternate !important; /* Lights animation */
}
@keyframes lights {
  100% {
    background-color: #007900;
  } /* Green */
  100% {
    background-color: #212529;
  } /* Dark */
}
.cta .svg {
  position: relative;
  font-size: 50px !important;
  top: 0;
  margin-left: 10px;
  fill: none;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}
.cta:hover:before {
  width: 100%;
  background: #00475da1;
  text-decoration: none !important;
}
.cta:active {
  transform: scale(0.95) !important;
}

.q {
  border-left: 3px solid orangered;
  border-radius: 2px;
}
.qoute-con {
  font-size: 13.7px;
}
.the-q {
  color: #e0e0e0;
}
.main-qoute-con {
  margin-bottom: -15px !important;
}
.qoute-author {
  color: cornflowerblue;
}

/* .invicecard {
  background-color: #052f30 !important;
  color: rgb(255, 255, 255) !important;
  border: 1px solid rgb(61, 131, 139) !important;
} */
.invicecard {
  background-color: #052f30 !important;
  color: rgb(255, 255, 255) !important;
  border: 1px solid rgb(61, 131, 139) !important;
}
.whyveridata {
  background-color: #012435 !important;
  border: 1px solid rgb(0, 190, 211) !important;
}
.whyveridata dt {
  font-size: 18px;
  font-weight: 700;
  color: white !important;
}
.whyveridata dd {
  font-size: 18px;
  font-weight: 700;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: white !important;
}

.invicecardtwo {
  background-color: #063611 !important;
  color: rgb(255, 255, 255) !important;
  border: 1px solid rgb(61, 139, 66) !important;
}
.verified-continer {
  background-color: #024d13 !important;
  color: rgb(255, 255, 255) !important;
  border: 1px solid rgb(132, 193, 136) !important;
}

.unverified-continer {
  background-color: #6b0202 !important;
  color: rgb(255, 255, 255) !important;
  border: 1px solid rgb(255, 0, 0) !important;
}
.clonestorev {
  border: 1px solid gray !important;
}

.typewriter-animation {
  overflow: hidden; /* Ensure text doesn't overflow its container */
  border-right: 0.15em solid orange; /* Cursor effect */
  white-space: nowrap; /* Keep text in one line */
  margin: 0 auto; /* Center the text */
  letter-spacing: 0.15em; /* Adjust letter spacing for better readability */
  animation: typing 3.5s steps(40, end); /* Animation details */
}

/* Keyframe animation */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
